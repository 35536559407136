import { create } from "zustand"

interface DialogState {
  isOpen: boolean
  isLogin: boolean
  setOpen: (b: boolean) => void
  setIsLogin: (b: boolean) => void
  close: () => void
  openLogin: () => void
  openRegister: () => void
}

const useLoginDialogStore = create<DialogState>((set) => ({
  isOpen: false,
  isLogin: true,
  setIsLogin: (b: boolean) => set({ isLogin: b }),
  setOpen: (b: boolean) => set({ isOpen: b }),
  openLogin: () => set({ isOpen: true, isLogin: true }),
  openRegister: () => set({ isOpen: true, isLogin: false }),
  close: () => set({ isOpen: false }),
}))

export function useLoginDialog() {
  const isOpen = useLoginDialogStore((state) => state.isOpen)
  const setOpen = useLoginDialogStore((state) => state.setOpen)
  const close = useLoginDialogStore((state) => state.close)
  const openLogin = useLoginDialogStore((state) => state.openLogin)
  const openRegister = useLoginDialogStore((state) => state.openRegister)
  const isLogin = useLoginDialogStore((state) => state.isLogin)
  const setIsLogin = useLoginDialogStore((state) => state.setIsLogin)

  return {
    isOpen,
    setOpen,
    close,
    openLogin,
    openRegister,
    isLogin,
    setIsLogin,
  }
}
